import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

const DitIsJCI = () => (
  <Layout>
    <SEO title="Contacteer ons" />
    <div class="pt-48">
      <div class="container mt-4 px-3 mx-auto flex flex-wrap flex-col md:flex-row items-center">
        <h3>We hebben je vraag goed ontvangen.</h3>
        <p className="mb-3">We hebben je vraag ontvangen en antwoorden je zo snel mogelijk dat we kunnen terug. Alvast bedankt voor uw interesse.</p>
      </div>
    </div>
  </Layout>
)

export default DitIsJCI
